<script lang="js">
import { validators } from '@/validations';
import VIcon from '@/components/Icon.vue';
import VTextbox from '@/components/Textbox.vue';

export default {
  data() {
    return {
      validPass: {
        number: false,
        capitalLetter: false,
        lowerCaseLetter: false,
        specialCharacter: false,
        min: false
      }
    }
  },
  components: {
    VIcon,
    VTextbox,
  },
  inject: {
    $validator: '$validator',
  },
  model: {
    prop: 'data',
    event: 'change',
  },
  props: {
    data: Object,
  },
  computed: {
    passwordMinMax() {
      if (this.data.senha) {
        return this.data.senha.length >= 8 && this.data.senha.length <= 16;
      }
      return false;
    },
    passwordNumberAndLetter() {
      if (this.data.senha) {
        return validators.password_one_letter(this.data.senha) && validators.password_one_number(this.data.senha);
      }
      return false;
    },
    passwordCapitalLetter() {
      if (this.data.senha) {
        return validators.password_one_capital_letter(this.data.senha);
      }
      return false;
    },
    passwordSpecialCharacter() {
      if (this.data.senha) {
        return validators.password_special_characters(this.data.senha);
      }
      return false;
    },
  },
  mounted() {
    const password = document.getElementsByName('password.password')[0];
    if (password) {
      password.addEventListener('keyup', (e) => {
        const { value } = e.target || null;
        this.validatePassword(value);
      });
    }
  },
  methods: {
    validatePassword(value) {
      this.validPass.min = value.length >= 10;
      this.validPass.number = validators.password_one_letter(value) && validators.password_one_number(value);
      this.validPass.capitalLetter = validators.password_one_capital_letter(value);
      this.validPass.lowerCaseLetter = validators.password_one_lower_case_letter(value);
      this.validPass.specialCharacter = validators.password_special_characters(value);
    },
  }
};
</script>

<template>
  <div class="user-password">
    <dl class="user-password__password-info">
      <dt class="user-password__password-info__title">
        Sua senha deve conter:
      </dt>
      <dd
        :class="{ 'on': validPass.min }"
        class="user-password__password-info__item">
        <v-icon class="user-password__password-info__item__icon" name="check"/>
        <span class="user-password__password-info__item__text">
          Mínimo de 10 caracteres
        </span>
      </dd>
      <dd
        :class="{ 'on': validPass.number }"
        class="user-password__password-info__item">
        <v-icon class="user-password__password-info__item__icon" name="check"/>
        <span class="user-password__password-info__item__text">
          Um número
        </span>
      </dd>
      <dd
        :class="{ 'on': validPass.lowerCaseLetter }"
        class="user-password__password-info__item">
        <v-icon class="user-password__password-info__item__icon" name="check"/>
        <span class="user-password__password-info__item__text">
          Uma letra minúscula
        </span>
      </dd>
      <dd
        :class="{ 'on': validPass.capitalLetter }"
        class="user-password__password-info__item">
        <v-icon class="user-password__password-info__item__icon" name="check"/>
        <span class="user-password__password-info__item__text">
          Uma letra maiúscula
        </span>
      </dd>
      <dd
        :class="{ 'on': validPass.specialCharacter }"
        class="user-password__password-info__item">
        <v-icon class="user-password__password-info__item__icon" name="check"/>
        <span class="user-password__password-info__item__text">
          Dois caracteres especiais (ex.: @#$%&amp;!?)
        </span>
      </dd>
    </dl>
    <v-textbox
      v-model="data.senha"
      :validate="{
        required: true,
        min: 10,
        password_one_capital_letter: true,
        password_one_lower_case_letter: true,
        password_one_number: true,
        password_special_characters: true,
      }"
      data-vv-as="Senha"
      data-vv-scope="password"
      label="Senha:"
      name="password.password"
      type="password" required/>
    <v-textbox
      v-model="data.confirmaSenha"
      :validate="`required|confirmed:password.password`"
      data-vv-as="senha"
      data-vv-scope="password"
      label="Confirmar a senha:"
      name="password.passwordConfirmation"
      type="password" required/>
    <div class="user-password__slot">
      <slot/>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.user-password {
  &__password-info {
    margin-bottom: 2rem;
    &__title {
      padding-bottom: 1rem;
    }
    &__item {
      padding: .25rem 0;
      &.on {
        ^&__icon {
          color: var(--color-green);
        }
      }
      &__icon {
        color: var(--color-grey-light);
        margin-right: .25rem;
        size: 1.25rem;
      }
      &__text {
        vertical-align: -.125rem;
      }
    }
  }
  &__slot {
    /* padding: 1.5rem; */
    &:empty {
      display: none;
    }
  }
}
</style>
