var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-password" },
    [
      _c("dl", { staticClass: "user-password__password-info" }, [
        _c("dt", { staticClass: "user-password__password-info__title" }, [
          _vm._v("\n      Sua senha deve conter:\n    "),
        ]),
        _c(
          "dd",
          {
            staticClass: "user-password__password-info__item",
            class: { on: _vm.validPass.min },
          },
          [
            _c("v-icon", {
              staticClass: "user-password__password-info__item__icon",
              attrs: { name: "check" },
            }),
            _c(
              "span",
              { staticClass: "user-password__password-info__item__text" },
              [_vm._v("\n        Mínimo de 10 caracteres\n      ")]
            ),
          ],
          1
        ),
        _c(
          "dd",
          {
            staticClass: "user-password__password-info__item",
            class: { on: _vm.validPass.number },
          },
          [
            _c("v-icon", {
              staticClass: "user-password__password-info__item__icon",
              attrs: { name: "check" },
            }),
            _c(
              "span",
              { staticClass: "user-password__password-info__item__text" },
              [_vm._v("\n        Um número\n      ")]
            ),
          ],
          1
        ),
        _c(
          "dd",
          {
            staticClass: "user-password__password-info__item",
            class: { on: _vm.validPass.lowerCaseLetter },
          },
          [
            _c("v-icon", {
              staticClass: "user-password__password-info__item__icon",
              attrs: { name: "check" },
            }),
            _c(
              "span",
              { staticClass: "user-password__password-info__item__text" },
              [_vm._v("\n        Uma letra minúscula\n      ")]
            ),
          ],
          1
        ),
        _c(
          "dd",
          {
            staticClass: "user-password__password-info__item",
            class: { on: _vm.validPass.capitalLetter },
          },
          [
            _c("v-icon", {
              staticClass: "user-password__password-info__item__icon",
              attrs: { name: "check" },
            }),
            _c(
              "span",
              { staticClass: "user-password__password-info__item__text" },
              [_vm._v("\n        Uma letra maiúscula\n      ")]
            ),
          ],
          1
        ),
        _c(
          "dd",
          {
            staticClass: "user-password__password-info__item",
            class: { on: _vm.validPass.specialCharacter },
          },
          [
            _c("v-icon", {
              staticClass: "user-password__password-info__item__icon",
              attrs: { name: "check" },
            }),
            _c(
              "span",
              { staticClass: "user-password__password-info__item__text" },
              [
                _vm._v(
                  "\n        Dois caracteres especiais (ex.: @#$%&!?)\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("v-textbox", {
        attrs: {
          validate: {
            required: true,
            min: 10,
            password_one_capital_letter: true,
            password_one_lower_case_letter: true,
            password_one_number: true,
            password_special_characters: true,
          },
          "data-vv-as": "Senha",
          "data-vv-scope": "password",
          label: "Senha:",
          name: "password.password",
          type: "password",
          required: "",
        },
        model: {
          value: _vm.data.senha,
          callback: function ($$v) {
            _vm.$set(_vm.data, "senha", $$v)
          },
          expression: "data.senha",
        },
      }),
      _c("v-textbox", {
        attrs: {
          validate: `required|confirmed:password.password`,
          "data-vv-as": "senha",
          "data-vv-scope": "password",
          label: "Confirmar a senha:",
          name: "password.passwordConfirmation",
          type: "password",
          required: "",
        },
        model: {
          value: _vm.data.confirmaSenha,
          callback: function ($$v) {
            _vm.$set(_vm.data, "confirmaSenha", $$v)
          },
          expression: "data.confirmaSenha",
        },
      }),
      _c("div", { staticClass: "user-password__slot" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }